import React from "react"
import styled from 'styled-components';

const TabBackground = styled.div`
    display: flex;
    padding: 5px 0 0 10px;
    background-color: #dbdbdb;
`;

type selected = { selected: boolean;}

const Tab = styled.div<selected>`
    padding: 15px 30px;
    background-color: #c5c5c5;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
    ${({ selected }: selected) => selected && `
        background-color: #f3f3f3;;
    `} 
`;

const NavigationTabs: React.FC = () => {
    return (
        <TabBackground>
            <Tab selected={true}>Run Modelling</Tab>
            <Tab selected={false} onClick={()=> process.env.REACT_APP_POWER_BI_LINK && (window.open(process.env.REACT_APP_POWER_BI_LINK,'_blank'))}>KPI Dashboard</Tab>
            <Tab selected={false} onClick={()=> process.env.REACT_APP_PICK_SPLIT_POWER_BI_LINK && (window.open(process.env.REACT_APP_PICK_SPLIT_POWER_BI_LINK,'_blank'))}>Pick Split Dashboard</Tab>
        </TabBackground>
    )
}

export default NavigationTabs